<template>
    <div class="px-3 py-2" style="direction:rtl">
        <b-sidebar aria-labelledby="sidebar-no-header-title" width="100vw" no-header id="cancel_order" backdrop style="direction:ltr" right title="add order" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" :style="`direction:`+$parent.lang.dir">
                    <div style="width:150px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span style="font-size:.8em;">{{tablename}}</span>
                    </div>
                    <div @click="hide" id="removevohclose" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{$parent.lang.close}}</span>
                    </div>
                    <v-divider></v-divider>
                </div>
                
                <div style="margin-left:25px;margin-right:25px;margin-bottom: 20px;" class="mt-4">
                    
                    <v-row style="direction:rtl">
                        <v-col cols="12" md="12" sm="12" style="padding-top:0px;">
                            <h5>{{ lang.order_details }}</h5>
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th class="text-center">{{ lang.orderid }}</th>
                                        <th class="text-center">{{ lang.mobile }}</th>
                                        <th class="text-center">{{ lang.full_name }}</th>
                                        <th class="text-center">{{ lang.total }}</th>
                                        <th class="text-center">{{ lang.vat }}</th>
                                        <th class="text-center">{{ lang.ftotal }}</th>
                                        <th class="text-center">{{ lang.order_date }}</th>
                                        <th class="text-center">{{ lang.order_time }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center">{{ orderid }}</td>
                                        <td class="text-center">{{ customer.mobile }}</td>
                                        <td class="text-center">{{ customer.full_name }}</td>
                                        <td class="text-center">{{ order.total }}</td>
                                        <td class="text-center">{{ order.vat }}</td>
                                        <td class="text-center">{{ order.ftotal }}</td>
                                        <td class="text-center">{{ order.order_date ? order.order_date.substring(0,10) : "-" }}</td>
                                        <td class="text-center">{{ order.order_date ? order.order_date.substring(11) : "-" }}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                        <v-col cols="12" md="12" sm="12" style="padding-top:0px;" v-if="orderid != 0">
                            <h5>{{ lang.order_items }}</h5>
                            <v-simple-table>
                                <thead>
                                    <tr>
                                        <th class="text-center">{{ lang.item_name }}</th>
                                        <th class="text-center">{{ lang.qtty }}</th>
                                        <th class="text-center">{{ lang.price }}</th>
                                        <th class="text-center">{{ lang.total }}</th>
                                        <th class="text-center">{{ lang.vat }}</th>
                                        <th class="text-center">{{ lang.ftotal }}</th>
                                        <th class="text-center">{{ lang.cancel_qtty }}</th>
                                        <th class="text-center">{{ lang.cancel_reson }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in order.rows" :key="index">
                                        <td class="text-center " style="padding:3px !important;">{{ item.item_name }}</td>
                                        <td class="text-center " style="padding:3px !important;">{{ item.qty }}</td>
                                        <td class="text-center " style="padding:3px !important;">{{ item.item_price }}</td>
                                        <td class="text-center " style="padding:3px !important;">{{ item.total }}</td>
                                        <td class="text-center " style="padding:3px !important;">{{ item.vat }}</td>
                                        <td class="text-center " style="padding:3px !important;">{{ item.ftotal }}</td>
                                        <td class="text-center " style="padding:3px !important;width:100px">
                                            <b-form-input
                                                :label="lang.mobile"
                                                v-model="canceled[item.id].canceled_qty"
                                                class="inborder"
                                                @change="checkQTy(canceled[item.id].canceled_qty,item.qty,item)"
                                                style="width:100px;"
                                                />
                                        </td>
                                        <td class="text-center">
                                            <b-form-input
                                                :label="lang.mobile"
                                                v-model="canceled[item.id].canceled_reson"
                                                class="inborder"
                                                style="padding:3px !important;"
                                                />
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                        <v-col cols="4">
                            <label>{{ lang.full_cancel_reson }}</label>
                            <b-form-textarea
                                :label="lang.mobile"
                                v-model="cancel_reson"
                                class="inborder"
                                style="padding:3px !important;"
                                />
                        </v-col>
                        <v-col cols="4">
                            
                        </v-col>
                        <v-col cols="4">
                            <br>
                            <v-btn class="me-3" @click="cancel(0)" style="color:#fff !important;background:darkred !important;box-shadow:none">{{ lang.full_cancel }}</v-btn>
                            <v-btn class="me-3" @click="cancel(1)" style="color:#fff !important;background:red !important;box-shadow:none">{{ lang.partial_cancel }}</v-btn>
                        </v-col>
                    </v-row>
                    <vue-snotify></vue-snotify>
                </div>
                </template>
               
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
// import posDiscount from '@/components/pos_discount.vue';
// import PosNotes from '@/components/pos-notes.vue';
// import PosAddinvoice from '@/components/pos-addinvoice.vue';

export default{
//   components: { posDiscount, PosNotes, PosAddinvoice },
    name: 'POS',
    data() {
        return {
            canceled: [],
            tableid: 0,
            tablename: '',
            orderid: 0,
            selectedIndex: -1,
            selectedItem: {},
            categories: [],
            subcats:[],
            itemrows: [],
            allitems: [],
            customer:{
                id: '',
                mobile: '',
                full_name: '',
                customerid: '',
            },
            company:{
                fullname: '',
                vatid: '',
            },
            discount: 0,
            notes: '',
            paytype: 1,
            invtype: 1,
            paytypes: [],
            paidamount: 0,
            order: {},
            cancel_reson: ''
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        invtypes: function(){
            return [
                {
                    text: this.lang.none_paid_invoice,
                    value: 2,
                },
                {
                    text: this.lang.paid_invoice,
                    value: 1,
                },
            ]
        },
        curency: function(){
            return this.lang.curency;
        },
        total_after_discount: function(){
            let t = 0;
            if(this.intotal == 0) return t;
            t = parseFloat(this.intotal) - parseFloat(this.discount);
            return this.$RoundNums(t,3);
        },
        intotal: function(){
            let t = 0;
            for(let i=0;i<this.itemrows.length;i++){
                t = parseFloat(t) + parseFloat(this.itemrows[i].total);
            }
            return this.$RoundNums(t,3);
        },
        invat: function(){
            let t = 0;
            // for(let i=0;i<this.itemrows.length;i++){
            //     t = parseFloat(t) + parseFloat(this.itemrows[i].vat);
            // }
            
        //    for(let i=0;i<this.itemrows.length;i++){
        //         t = parseFloat(t) + parseFloat(this.itemrows[i].vat) + parseFloat(this.itemrows[i].smoke);
        //     }
            t = parseFloat(this.total_after_discount) * parseFloat(115/100)
            return this.$RoundNums(t,3);
        },
        inftotal: function(){
            let t = 0;
            // for(let i=0;i<this.itemrows.length;i++){
            //     t = parseFloat(t) + parseFloat(this.itemrows[i].ftotal);
            // }
            // t = parseFloat(t) * parseFloat(this.discountper)
            t = parseFloat(this.total_after_discount) + this.invat;
            return this.$RoundNums(t,3);
        },
        discountper: function() {
            let t = 0;
            if(this.intotal == 0) return t;
            t = parseFloat(this.discount) / parseFloat(this.intotal);
            
            return this.$RoundNums(t,3);
        }
    },
    methods: {
        checkQTy(r,q,item){
            if(r > q){
                let message = "لقد قمت بطلب ارجاع كمية اكبر من الكمية المطلوبة .. سيتم تجاهل القيمة الحالية وارجاع كامل الصنف";
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.canceled[item.id].canceled_qty = q;
                console.log([
                    this.canceled[item.id].canceled_qty,
                    r,q,item.id
                ]);
                
                return false;
            }
        },
        cancel(id){
            let returnit = false;
            let checkit = true;
            if(id == 0){
                if(this.cancel_reson == ''){
                    let message = "انت تقوم بالغاء كامل الطلب يرجى اضافة سبب الغاء كامل الطلب";
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    checkit = false;
                }
                returnit = true;
            }else if(id == 1){ 
                this.canceled.forEach(value => {
                    if(checkit){
                        if(value.canceled_qty != 0 && value.canceled_reson == ''){
                            let message = "هناك اصناف قمت بالغاء جزء او كامل الكمية المطلوبة ولم تقم باضافة سبب الالغاء يرجى ادخال سبب الالغاء للمتابعة";
                            this.$snotify.error(message, 'تنبيه', {
                                timeout: 1000000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                position: SnotifyPosition.centerCenter,
                                buttons: [
                                    {
                                        text: this.$parent.lang.close, 
                                        action: (toast) => {
                                            this.$snotify.remove(toast.id); 
                                        } 
                                    },
                                ]
                            });
                            checkit = false;
                            return false;
                        }else if(value.canceled_qty != 0 && value.canceled_reson != ''){
                            returnit = true;
                        }
                    }
                })
            }
            if(checkit){
                if(!returnit){
                    let message = "لم تقم باختيار الاصناف التي ترغب بارجاع جزء او كامل الكمية يرجى اختيار صنف على الاقل";
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    return false;
                }else{
                    let message = "تم الالغاء بنجاح";
                    this.$snotify.success(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    document.getElementById('removevohclose').click();
                    this.$parent.getOrder();
                    return false;
                }
            }
            
        },
        getOrder(){
            const post = new FormData();
            post.append('type','getOrder');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[orderid]',this.orderid);
            let x = [];
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data){
                    for(let i=0;i<response.data.results.data.rows.length;i++){
                        this.canceled[response.data.results.data.rows[i].id] = {
                            canceled_qty: 0,
                            canceled_reson: '',
                            id: response.data.results.data.rows[i].id,
                        }
                    }
                    this.order = response.data.results.data
                }
            })
            
        },
        changeColor(index){
            for(let i=0;i<this.categories.length;i++){
                if(index == i){
                    document.getElementById('cats_'+i).classList.add("btn-balck");
                    document.getElementById('cats_'+i).classList.remove("btn-yallow");
                }else{
                    document.getElementById('cats_'+i).classList.add("btn-yallow");
                    document.getElementById('cats_'+i).classList.remove("btn-balck");
                }
            }
        },
        getPaymentMethod(){
            const post = new FormData();
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]',1);
            let x = [];
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    // console.log(res);
                    for(let i = 0;i<res.results.data.length;i++){
                        if(i == 0){
                            this.paytype = res.results.data[i].id;
                        }
                        x.push({
                            text: this.$cookies.get(this.$store.state.LangCooki) == "ar" ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                            value: res.results.data[i].id
                        })
                    }
                    this.paytypes = x;
                    // this.paytypes.push(
                    //     {
                    //         text: this.lang.multi_payment,
                    //         value: -1
                    //     }
                    // )
                }
            )
        },
        cancelOrder(i){
            this.orderid = 0;
            this.tableid = 0;
            this.tablename = '';
            this.subcats = []
            this.itemrows =  []
            this.allitems =  []
            this.customer = {
                id: '',
                mobile: '',
                full_name: '',
                customerid: '',
            }
            this.company = {
                fullname: '',
                vatid: '',
            }
            this.discount =  0
            this.notes = '';
            this.paytype = 1;
            this.invtype = 1;
            this.paidamount = 0;
            if(i == 1){
                document.getElementById('vohclose').click();
            }
        },
        getName(){
            const post = new FormData();
            post.append('type','customerInfo');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[mobile]',this.customer.mobile);
            post.append('data[customerid]',this.customer.customerid);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    
                    if(res.error.number == 200){
                        this.customer.id = res.results.data['id'];
                        this.customer.mobile = res.results.data['mobile'];
                        this.customer.full_name = res.results.data['full_name'];
                        this.customer.customerid = res.results.data['customerid'];
                        this.company.fullname = res.results.data['company_name'];
                        this.company.vatid = res.results.data['company_vatid'];
                    }
                }
            )
        },
        updatePaidAmount(){
            this.paidamount = this.inftotal;
        },
        addItem(item){
            let check = false;
            for(let i=0;i<this.itemrows.length;i++){
                if(this.itemrows[i].id == item.id){
                    this.itemrows[i].qty = parseFloat(this.itemrows[i].qty) + parseFloat(1);
                    this.itemrows[i].ftotal = this.$RoundNums(parseFloat(this.itemrows[i].qty) * parseFloat(this.itemrows[i].item_price),3);
                    this.itemrows[i].total = this.$RoundNums(parseFloat(this.itemrows[i].qty) * parseFloat(this.itemrows[i].sprice),3);
                    this.itemrows[i].vat = this.$RoundNums(parseFloat(this.itemrows[i].qty) * parseFloat(this.itemrows[i].vat));
                    this.itemrows[i].smoke = this.$RoundNums(parseFloat(this.itemrows[i].qty) * parseFloat(this.itemrows[i].smoke),3);
                    check = true;
                }
            }
            if(!check){
                this.itemrows.push(item);
                this.paidamount = this.inftotal;
            }
            for(let i=0;i<this.itemrows.length;i++){
                if(this.itemrows[i].id == item.id){
                    this.itemrows[i].qty = parseFloat(this.itemrows[i].qty);
                    this.itemrows[i].ftotal = this.$RoundNums(parseFloat(this.itemrows[i].qty) * parseFloat(this.itemrows[i].item_price),3);
                    this.itemrows[i].total = this.$RoundNums(parseFloat(this.itemrows[i].qty) * parseFloat(this.itemrows[i].sprice),3);
                    this.itemrows[i].vat = this.$RoundNums(parseFloat(this.itemrows[i].qty) * parseFloat(this.itemrows[i].vat));
                    this.itemrows[i].smoke = this.$RoundNums(parseFloat(this.itemrows[i].qty) * parseFloat(this.itemrows[i].smoke),3);
                    check = true;
                }
            }
        },
        selectThie(item,index){
           if(this.selectedIndex != -1){
               if(this.selectedIndex != index){
                   document.getElementById('item_'+this.selectedIndex).style.background = '#FFF';
                   this.selectedIndex = index;
                    this.selectedItem = item;
                    document.getElementById('item_'+index).style.background = '#ccc';
                    return false;
               }
               this.selectedIndex = -1;
               this.selectedItem = {};
               document.getElementById('item_'+index).style.background = '#FFF';
           }else{
                this.selectedIndex = index;
                this.selectedItem = item;
                document.getElementById('item_'+index).style.background = '#ccc';
               
           }
        },
        delSel(){
            if(this.selectedIndex != -1){
                this.itemrows.splice(this.selectedIndex,1);
                document.getElementById('item_'+this.selectedIndex).style.background = '#FFF';
                this.selectedIndex = -1;
                this.selectedItem = {};
            }
        },
        addThis(n){
            if(this.selectedIndex != -1){
                this.itemrows[this.selectedIndex].qty = n;
                this.itemrows[this.selectedIndex].total = this.$RoundNums(parseFloat(n) * parseFloat(this.itemrows[this.selectedIndex].sprice),3);
                this.itemrows[this.selectedIndex].vat = this.$RoundNums(parseFloat(n) * parseFloat(this.itemrows[this.selectedIndex].svat),3);
                this.itemrows[this.selectedIndex].ftotal = this.$RoundNums(parseFloat(this.itemrows[this.selectedIndex].total) + parseFloat(this.itemrows[this.selectedIndex].vat),3);
                document.getElementById('item_'+this.selectedIndex).style.background = '#FFF';
                this.selectedIndex = -1;
                this.selectedItem = {};
            }
        },
        addOne(){
            if(this.selectedIndex != -1){
                let n = +this.itemrows[this.selectedIndex].qty + 1;
                this.itemrows[this.selectedIndex].qty = n;
                this.itemrows[this.selectedIndex].total = this.$RoundNums(parseFloat(n) * parseFloat(this.itemrows[this.selectedIndex].sprice),3);
                this.itemrows[this.selectedIndex].vat = this.$RoundNums(parseFloat(n) * parseFloat(this.itemrows[this.selectedIndex].svat),3);
                this.itemrows[this.selectedIndex].ftotal = this.$RoundNums(parseFloat(this.itemrows[this.selectedIndex].total) + parseFloat(this.itemrows[this.selectedIndex].vat),3);                
            }
        },
        minOne(){
            if(this.selectedIndex != -1 && this.itemrows[this.selectedIndex].qty > 1){
                let n = +this.itemrows[this.selectedIndex].qty - 1;
                this.itemrows[this.selectedIndex].qty = n;
                this.itemrows[this.selectedIndex].total = this.$RoundNums(parseFloat(n) * parseFloat(this.itemrows[this.selectedIndex].sprice),3);
                this.itemrows[this.selectedIndex].vat = this.$RoundNums(parseFloat(n) * parseFloat(this.itemrows[this.selectedIndex].svat),3);
                this.itemrows[this.selectedIndex].ftotal = this.$RoundNums(parseFloat(this.itemrows[this.selectedIndex].total) + parseFloat(this.itemrows[this.selectedIndex].vat),3);
            }
        },
        getCats(item){
            if(item.type != 0 && item.id != 0){
                this.getProducts(item.id);
                return false;
            }
            const post = new FormData();
            post.append('type', 'getPCats');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[id]',item.id);
            post.append('data[type]',item.type);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
               
                if(item.id == 0){
                    this.categories = res.results.data;
                }else{
                    this.allitems = [];
                    this.subcats = res.results.data;
                }
            })
        },
        getProducts(catid){
            this.subcats = [];
            const post = new FormData();
            post.append('type', 'getCatProducts');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[id]',catid);

            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                 console.log(res);
                this.allitems = res.results.data;
            })
        },
        addInvoice(){
            if(this.itemrows.length == 0){
                this.$snotify.error(this.lang.you_can_not_add_empty_invoice, this.lang.add_invoice, {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                return false;
            }
            
            if(this.customer.mobile == '' || typeof this.customer.mobile === 'undefined'){
                this.customer.id = 1;
                this.customer.mobile = '1234567891';
                this.customer.full_name = 'عميل غير مدرج';
                this.customer.customerid = '6001';
            }
            const post = new FormData();
            post.append("type" , "addPOSorder"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));

            for(const [key, value] of Object.entries(this.customer)){
                post.append('data[customer]['+key+']]',value);
            }
            for(const [key, value] of Object.entries(this.company)){
                post.append('data[company]['+key+']]',value);
            }
            for(let i = 0; i < this.itemrows.length;i++){
                for(const [key, value] of Object.entries(this.itemrows[i])){
                    post.append('data[rows]['+i+']['+key+']]',value);
                }
            }
            
            post.append("data[discount]",this.discount);
            post.append("data[tableid]",this.tableid);
            post.append("data[orderid]",this.orderid);
            post.append("data[notes]",this.notes);
            post.append("data[paytype]",this.paytype);
            post.append("data[invtype]",this.invtype);
            post.append("data[paidamount]",this.paidamount);
            post.append("data[intotal]",this.intotal);
            post.append("data[invat]",this.invat);
            post.append("data[inftotal]",this.inftotal);
            post.append("data[total_after_discount]",this.total_after_discount);
            axios.post(
                this.$store.state.SAMCOTEC.r_path,  post
            ).then((response) => {
                const res = response.data;
                console.log(res);
                //this.printPDF(res.results.data);
               this.cancelOrder(1);
               this.$parent.getTables();
            })
        },
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print-80m.php?invid='+id;
        },
    },
    created() {
        this.getCats({id:0,type:0});
        this.getPaymentMethod();
        // this.getOrder();
    },
}
</script>

<style>
th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}
.invoicetable{
    max-height:calc(100vh - 325px);
    min-height:calc(100vh - 325px);
    overflow: auto;
}
#countbtns{
    background:#eee;
    border:1px solid #ccc;
    text-align:center;
    position: absolute;
    bottom: 1px;
    left:1px;
    right:1px;
}
.mxmnheight{
    max-height:calc(100vh - 50px);
    min-height:calc(100vh - 50px);
    position: relative;
}
.mxmnheight-200{
    max-height:calc(100vh - 80px);
    overflow: auto;
}
.wp-100{
    width: 150px;
    padding:10px;
    margin-inline-start: 3px;
}
.wp-150{
    padding-top:10px;
    width: calc(95%/5);
    height:65px;
    margin-inline-start: 3px;
    margin-top: 3px;
    background:#ccc;
    color:#000;
    transition: 1s;
}
.wp-150:hover{
    background:#222;
    color:#fff;
    transition: 1s;
}
.itemrowstd{
    transition: 1s;
    height: 40px !important;
}
.btn-gray{
    background:#6c757d;
    color: #fff;
    border-radius: 7px;
}
.btn-orange,.btn-orange:active,.btn-orange:hover,.btn-orange:visited{
    background: #fd7e14 !important;
    border-radius: 7px;
}
.btn-balck,.btn-balck:active,.btn-balck:hover,.btn-balck:visited{
    background: #000 !important;
    color:#fff !important;
    border-radius: 7px;
}
.btn-yallow,.btn-yallow:active,.btn-yallow:hover,.btn-yallow:visited{
    background: #ffc107 !important;
    color:black !important;
    border-radius: 7px;
}
.btn-primary {
    color: #fff !important;
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    border-radius: 7px;
}
</style>