<template>
    <div>
        <header-portrate />

        <div class="m-2">
            <div style="padding:5px;margin:5px;">
                <v-row dense>
                    <v-col
                        v-for="(item,index) in tablerows"
                        :key="index"
                    >
                        <v-card
                            class="mx-auto"
                            max-width="344"
                            min-width="344"
                            outlined
                            style="border:1px solid #000 !important;"
                        >
                            <v-list-item three-line>
                                <v-list-item-content style="">
                                    <div class="text-overline mb-4">
                                        {{ item[`name`+lang.langname] }}
                                    </div>
                                    <v-list-item-title class="text-h6 mb-1" style="">
                                    {{item.agent}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle style="">
                                        {{lang.booktime}} : {{item.booktime}} 
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-avatar
                                    tile
                                    size="80"
                                    :color="item.bgcolor"
                                >
                                {{item.ftotal}}
                                <br />{{table[item.id]}}
                                </v-list-item-avatar>
                            </v-list-item>

                            <v-card-actions>
                                <v-btn
                                    color="primary"
                                    rounded
                                    text
                                    v-b-toggle.add_order
                                    @click="updateAddOrder(item)"
                                    style="font-size:.8rem !important;"
                                >
                                    {{ lang.add_order }}
                                </v-btn>
                                <v-btn
                                    color="red"
                                    rounded
                                    text
                                    v-b-toggle.cancel_order
                                    @click="deleteAddOrder(item)"
                                    style="font-size:.8rem !important;"
                                    v-if="item.order[0].id"
                                >
                                    {{lang.cancel_order}}
                                </v-btn>
                                <v-btn
                                    color="green"
                                    rounded
                                    text
                                    v-b-toggle.view_order
                                    @click="viewOrder(item)"
                                    style="font-size:.8rem !important;"
                                    v-if="item.order[0].id"
                                >
                                    {{ lang.view_order }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
            <vue-snotify></vue-snotify>
        </div>
        <Footer />
        <addOrder ref="addOrder" />
        <deleteOrder ref="deleteOrder" />
        <cancelOrder ref="cancelOrder" />
        <viewOrder ref="viewOrder" />
    </div>
</template>

<script>
import axios from 'axios'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import addOrder from '@/components/add-order.vue'
import cancelOrder from '@/components/cancel-order.vue'
import deleteOrder from '@/components/delete-order.vue'
import viewOrder from '@/components/view-order.vue'
export default{
  components: {HeaderPortrate, Footer, addOrder, deleteOrder,cancelOrder,viewOrder },
    name: 'POS',
    data() {
        return {
            tablerows: '',
            table: [],
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        
    },
    methods: {
        doTimer(){
            let time = [];
            let def = 0;
            const timenow = Math.floor(Date.now() / 1000);
            for(let i=0;i<this.tablerows.length;i++){
                if(this.tablerows[i].start == 0){
                    time[this.tablerows[i].id] = '';
                    def = 0;
                }else{
                    def = Math.floor(timenow - this.tablerows[i].start)
                    time[this.tablerows[i].id] = this.getTimer(def);
                }
            }
            this.table = time;
        },
        getTimer(def){
            let hours = 0;
            let men = 0;
            let sec = 0;
            hours = Math.floor(def / 3600);
            def = Math.floor(def - hours*3600);
            men = Math.floor(def / 60);
            def = Math.floor(def - men*60);
            sec = def;
            let final = "";
            if(hours < 10) final = "0"+hours;
            else final = hours;
            if(men < 10) final = final+":0"+men;
            else final = final+":"+men;
            if(sec < 10) final = final+":0"+sec;
            else final = final+":"+sec;
            return final;
        },
        updateAddOrder(item){
            this.$refs.addOrder.cancelOrder(0);
            this.$refs.addOrder.tableid = item.id;
            this.$refs.addOrder.tablename = item.namear;
            this.$refs.addOrder.orderid = item.order[0].id !== 'undefined' ? item.order[0].id : 0;
            this.$refs.addOrder.customer.mobile = item.order[0].mobile !== 'undefined' ? item.order[0].mobile : '';
            this.$refs.addOrder.getName();
            
        },
        deleteAddOrder(item){
            this.$refs.cancelOrder.cancelOrder(0);
            this.$refs.cancelOrder.tableid = item.id;
            this.$refs.cancelOrder.tablename = item.namear;
            this.$refs.cancelOrder.orderid = item.order[0].id !== 'undefined' ? item.order[0].id : 0;
            this.$refs.cancelOrder.customer.mobile = item.order[0].mobile !== 'undefined' ? item.order[0].mobile : '';
            this.$refs.cancelOrder.getName();
            this.$refs.cancelOrder.getOrder();
        },
        viewOrder(item){
            this.$refs.viewOrder.cancelOrder(0);
            this.$refs.viewOrder.tableid = item.id;
            this.$refs.viewOrder.tablename = item.namear;
            this.$refs.viewOrder.orderid = item.order[0].id !== 'undefined' ? item.order[0].id : 0;
            this.$refs.viewOrder.customer.mobile = item.order[0].mobile !== 'undefined' ? item.order[0].mobile : '';
            this.$refs.viewOrder.getName();
            this.$refs.viewOrder.getOrder();
        },
        getTables(){
            const post = new FormData();
            post.append("type","getTables");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[get]",'all');
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    console.log(res);
                    this.tablerows = res.results.data;
                }
            )
        }
    },
    created() {
        this.getTables();
        setInterval(() => {this.doTimer();},1000);
        // setInterval(() => {this.getTables();},10000);
    },
}
</script>

<style>
th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}
.invoicetable{
    max-height:calc(100vh - 325px);
    min-height:calc(100vh - 325px);
    overflow: auto;
}
#countbtns{
    background:#eee;
    border:1px solid #ccc;
    text-align:center;
    position: absolute;
    bottom: 1px;
    left:1px;
    right:1px;
}
.mxmnheight{
    max-height:calc(100vh - 50px);
    min-height:calc(100vh - 50px);
    position: relative;
}
.mxmnheight-200{
    max-height:calc(100vh - 80px);
    overflow: auto;
}
.wp-100{
    width: 150px;
    padding:10px;
    margin-inline-start: 3px;
}
.wp-150{
    padding-top:10px;
    width: calc(95%/5);
    height:65px;
    margin-inline-start: 3px;
    margin-top: 3px;
    background:#ccc;
    color:#000;
    transition: 1s;
}
.wp-150:hover{
    background:#222;
    color:#fff;
    transition: 1s;
}
.itemrowstd{
    transition: 1s;
    height: 40px !important;
}
.btn-gray{
    background:#6c757d;
    color: #fff;
    border-radius: 7px;
}
.btn-orange,.btn-orange:active,.btn-orange:hover,.btn-orange:visited{
    background: #fd7e14 !important;
    border-radius: 7px;
}
.btn-balck,.btn-balck:active,.btn-balck:hover,.btn-balck:visited{
    background: #000 !important;
    color:#fff !important;
    border-radius: 7px;
}
.btn-yallow,.btn-yallow:active,.btn-yallow:hover,.btn-yallow:visited{
    background: #ffc107 !important;
    color:black !important;
    border-radius: 7px;
}
.btn-primary {
    color: #fff !important;
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    border-radius: 7px;
}
</style>